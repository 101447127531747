<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">绩效模拟测算</h2>
                <el-row class="hosrow">
                    <div class="Basicbutto">
                        <el-button type="primary" @click="createDetec">创建测算规则</el-button>
                    </div>
                </el-row>
                <el-table :data="tableCreateDeData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="模拟测算名称" align="center">
                    </el-table-column>
                    <el-table-column prop="departmentName" label="科室名称" align="center">
                    </el-table-column>
                    <el-table-column prop="simulationMonth" label="模拟测算月份" align="center" width="110">
                    </el-table-column>
                    <el-table-column prop="endMonth" label="参考当量" align="center" width="110">
                        <template slot-scope="scope">
                            <div v-if="scope.row.endMonth == scope.row.startMonth">
                                {{ scope.row.year }}年{{ scope.row.startMonth }}月
                            </div>
                            <div v-else-if="scope.row.endYear == ''">
                                {{ scope.row.year }}年{{ scope.row.startMonth }}月
                            </div>
                            <div v-else>
                                {{ scope.row.year }}年{{ scope.row.startMonth }}月<br />
                                -{{ scope.row.endYear }}年{{ scope.row.endMonth }}月
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="diffType" label="计算难度系数" align="center" width="110">
                        <template slot-scope="scope">
                            <div v-if="scope.row.diffType == 1">是</div>
                            <div v-else-if="scope.row.diffType == 2">否</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="monthPrice" label="测算月份绩效薪酬" align="center">
                    </el-table-column>
                    <el-table-column prop="createDate" label="创建时间" align="center" width="160">
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="130">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="WorkMonitData(scope.row)"><i class="el-icon-notebook-2"></i></el-button>
                            <el-button size="mini" type="danger" @click="WorkMonitDelete(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 创建测算规则弹出框 -->
        <el-dialog title="创建测算规则" :visible.sync="createDetectionVisible" width="440px" :before-close="createDetectionVisibleClose">
            <el-form :model="AWorkMonitModel" ref="AWorkMonitRef" :rules="AWorkMonitRules" class="WorkMForm" label-width="155px">
                <el-form-item label="名称：" prop="name">
                    <el-input v-model="AWorkMonitModel.name"></el-input>
                </el-form-item>
                <el-form-item label="选择科室：" prop="type">
                    <el-select v-model="AWorkMonitModel.type" placeholder="请选择" style="width: 200px" @change="typeKeshiValueChange(AWorkMonitModel.type)">
                        <el-option v-for="item in Keshitypeoptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="模拟测算月份：" prop="endMonthzhi">
                    <el-date-picker style="width: 202px" v-model="AWorkMonitModel.endMonthzhi" type="month" format="yyyy-MM" value-format="yyyy-MM" placeholder="选择模拟测算月份" :picker-options="pickerOptions" @change="changeOptiosEnd">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="参考当量：" prop="yearzhi" class="reference">
                    <div class="dataInterval">
                        <el-input class="dataInput" v-model="AWorkMonitModel.yearzhi" :readonly="true" @focus="dataInterval" style="width: 170px !important"></el-input>

                        <!-- 参考当量值 -->
                        <!-- <el-input
              v-model="EquivalentValue"
              class="inputWidth"
              style="width: 80px !important"
              @focus="findvalent"
            ></el-input> -->
                        <!-- <i class="el-icon-date" @click="dataInterval"></i> -->
                    </div>
                    <div class="referencetips" v-show="referenceShow">
                        请先选择科室和模拟测算月份
                    </div>
                </el-form-item>

                <!-- 参考当量值 -->
                <el-form-item label="" prop="EquivalentValue" class="EquivalentValue">
                    <div>
                        <el-input v-model="AWorkMonitModel.EquivalentValue" class="inputWidth" style="width: 80px !important" ref="findTagInput"></el-input>
                    </div>
                </el-form-item>

                <el-form-item label="计算难度系数：" prop="diffType">
                    <el-select v-model="AWorkMonitModel.diffType" placeholder="请选择" style="width: 203px">
                        <el-option v-for="item in typeoptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="测算月份绩效薪酬：" prop="monthPrice">
                    <el-input v-model="AWorkMonitModel.monthPrice"></el-input>
                </el-form-item>

                <el-form-item>
                    <div class="btnCen">
                        <el-button        v-if="addCreatClick"        type="primary" class="depar-dia"        :loading="addCreateDis"        @click="addCreateDetecItem"       >创建</el-button>
                              <el-button        v-else        type="info" class="depar-dia"        :loading="addCreateDis"       ></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 科室绩效模拟测算弹出框 -->
        <el-dialog :title="monitorTitle" :visible.sync="createEchartVisible" width="90%" :before-close="createEchartVisibleClose">
            <el-form class="twoForm" :inline="true" :model="getworkldModel" ref="getworkldRef" :rules="getworkldRules">
                <div class="twoFormDiv">
                    <el-form-item>
                        <div>
                            模拟测算月份：{{ simulationMonth }}&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </el-form-item>
                    <el-form-item label="工作当量：" prop="workValue">
                        <el-input v-model="getworkldModel.workValue" style="width: 100px" @change="workValuehE($event)"></el-input><span>&nbsp;%&nbsp;&nbsp;&nbsp;</span>
                    </el-form-item>
                    <el-form-item label="收支余：" prop="income">
                        <el-input v-model="getworkldModel.income" style="width: 100px" @change="incomehE($event)"></el-input><span>&nbsp;%&nbsp;&nbsp;&nbsp;</span>
                    </el-form-item>
                    <el-form-item>
                        <el-button        v-if="cesuanClick"        type="primary"        :loading="cesuanClickKing"        @click="simulationCalculate"       >测算</el-button>
                              <el-button        v-else        type="info"        :loading="cesuanClickKing"       ></el-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="exportExce" v-if="cesuanExpClick" :loading="cesuanExpClickTwo">导出数据</el-button>
                        <el-button type="info" v-else :loading="cesuanExpClickTwo"></el-button>
                    </el-form-item>
                </div>
            </el-form>
            <el-table :cell-style="columnStyle" :data="tableSimulationData" border center style="width: 95%; margin: 0 auto">
                <el-table-column prop="rateOfChange" label="难度系数变化率" align="center" width="90">
                    <template slot-scope="scope">
                        <div>{{ scope.row.rateOfChange }}%</div>
                    </template>
                </el-table-column>
                <el-table-column prop="oneValue" label="参考当量" align="center" width="80">
                </el-table-column>
                <el-table-column prop="adjustedEquivalent" label="调整后当量值" align="center" width="70">
                </el-table-column>
                <el-table-column prop="twoValue" label="标化工作总量" align="center" width="90">
                </el-table-column>
                <el-table-column prop="strategicPriceWorkload" label="策略价标化工作总量" align="center">
                </el-table-column>
                <el-table-column prop="threeValue" label="绩效薪酬" align="center">
                </el-table-column>
                <el-table-column prop="fourValue" label="难度系数" align="center">
                </el-table-column>
                <el-table-column prop="fiveValue" label="100%工作当量测算结果" align="center" width="110">
                </el-table-column>
                <el-table-column prop="strategicPriceCalculation" label="100%工作当量策略价测算结果" align="center">
                </el-table-column>
                <el-table-column prop="sixValue" label="自定义当量测算结果" align="center" width="100">
                </el-table-column>
                <el-table-column prop="strategicPriceCustomEquivalent" label="自定义当量策略价测算结果" align="center">
                </el-table-column>
                <el-table-column prop="sevenValue" label="自定义当量测算结果差值" align="center" width="110">
                </el-table-column>
                <el-table-column prop="eightValue" label="增加量" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.eightValue">{{ scope.row.eightValue }}%</div>
                        <div v-else></div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <!-- 参考当量弹出框 -->
        <el-dialog title="选择参考当量" :visible.sync="dataIntervalVisible" width="400px">
            <el-button @click="BetweenMonth">月份区间选择</el-button>
            <el-button @click="oneMonth">选择一个月</el-button>
        </el-dialog>
        <!-- 月份区间选择 -->
        <el-dialog title="选择参考当量" :visible.sync="dataBetweenMonthVisible" width="580px" :before-close="dataBetweenMonthVisibleClose">
            <el-form :model="DateBetweenMonthModel" ref="DateBetweenMonthRef" :rules="DateBetweenMonthRules" :inline="true">
                <el-form-item prop="MonthDate">
                    <el-date-picker v-model="DateBetweenMonthModel.MonthDate" type="monthrange" format="yyyy-MM" value-format="yyyy-MM" align="right" unlink-panels range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" :picker-options="pickerOptionsEndequivalent" @change="changeEndequivalent">
                    </el-date-picker>
                </el-form-item>
                <br />
                <el-form-item>
                    <el-button type="primary" @click="DateBetweenMonthPrimary">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 选择一个月 -->
        <el-dialog title="选择参考当量" :visible.sync="dataOneMonthVisible" width="480px" :before-close="dataOneMonthVisibleClose">
            <el-form :model="DateOneMonthModel" ref="DateOneMonthRef" :rules="DateOneMonthRules" :inline="true" style="display: flex; flex-direction: column">
                <el-form-item prop="MonthOnempnthTime">
                    <el-date-picker v-model="DateOneMonthModel.MonthOnempnthTime" type="month" format="yyyy-MM" value-format="yyyy-MM" placeholder="选择参考当量" :picker-options="pickerOptionsone" @change="changeOptiosOnemonth">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="DateOneMonthPrimary">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    data() {
        var checkAge = (rule, value, callback) => {
            if (!value && String(value) !== "0") {
                return callback(new Error("不能为空"));
            }
            setTimeout(() => {
                if (!/^\d+$|^\d*\.\d+$/g.test(value)) {
                    callback(new Error("请输入数字"));
                } else {
                    callback();
                }
            }, 100);
        };

        var departCheck = (rule, value, callback) => {
            if (this.referenceShow == false) {
                if (!value && String(value) !== "0") {
                    return callback(new Error("参考当量不能为空"));
                }
                setTimeout(() => {
                    callback();
                }, 100);
            }
        };

        return {
            // 点击创建的时候设置禁用
            addCreateDis: false,
            addCreatClick: true,
            // 参考当量-月份区间选择
            creactMonthArr: [],
            pickerOptionsEndequivalent: {
                disabledDate: (time) => {
                    // console.log(time)
                    const year = time.getFullYear();
                    let month = time.getMonth() + 1;
                    if (month < 10) month = "0" + month;
                    const ym = year + "-" + month;
                    // console.log(this.creactMonthArr)
                    // //把所有年月和需要建立的月份匹配,把没有匹配上的返回出去,让月份选择器可选
                    return !this.creactMonthArr.includes(ym);
                },
            },
            value1: "",
            value2: "",
            // 请先选择科室的显示和隐藏
            referenceShow: false,
            pickerOptions: {
                disabledDate: (time) => {
                    return time > Date.now();
                },
            },
            // 参考当量 选择一个月
            pickerOptionsone: {
                disabledDate: (time) => {
                    const year = time.getFullYear();
                    let month = time.getMonth() + 1;
                    if (month < 10) month = "0" + month;
                    const ym = year + "-" + month;
                    // console.log(ym)
                    // console.log(this.creactMonthArr)
                    // //把所有年月和需要建立的月份匹配,把没有匹配上的返回出去,让月份选择器可选
                    return !this.creactMonthArr.includes(ym);
                },
                // disabledDate: (time) => {
                //   let arr = this.DlYear;
                //   var lj = true;
                //   arr.forEach((i) => {
                //     lj =
                //       lj &&
                //       (time.getTime() < new Date(i, 1, 0) ||
                //         time.getTime() > new Date(i, 12, 0));
                //   });
                //   return lj;
                // },
            },
            pickerOptionsBetweenEnd: {
                disabledDate: (time) => {
                    let minYear = this.Cyear;
                    this.startYearTime = minYear;
                    let man = this.startTime;

                    // 可选年份的区间
                    let arr = this.DlYear;
                    // 可选择的最大的年份
                    let lastYear = arr[arr.length - 1];
                    // console.log(lastYear);
                    return (
                        //   判断今天日期之后的日期不可选
                        // time > Date.now() ||
                        //   判断只能选择第一个框年份的日期
                        time.getTime() > new Date(minYear, 12, 0) ||
                        // // 判断第一个框选择的日期之前的不可以选择
                        time.getTime() < new Date(man).setHours(0, 0, 0, 0)
                    );
                },
            },
            // 导出按钮置灰
            cesuanExpClick: true,
            cesuanExpClickTwo: false,
            // 模拟测试的月份
            MoNCendmonth: "",
            // 设置默认年份
            startYearTime: "",
            getworkldModel: {
                workValue: "",
                income: "",
            },
            dataOneMonthVisible: false,
            dataBetweenMonthVisible: false,
            getworkldRules: {
                workValue: [
                    {
                        required: true,
                        message: "请输入工作当量",
                        trigger: "blur",
                    },
                ],
                income: [
                    {
                        required: true,
                        message: "请输入收支余",
                        trigger: "blur",
                    },
                ],
            },
            typeoptions: [
                {
                    value: "1",
                    label: "是",
                },
                {
                    value: "2",
                    label: "否",
                },
            ],
            startTime: "",
            //参考当量年份
            Cyear: "",
            // 模拟测算月份的年份
            Eyear: "",
            // 参考当量开始月份
            Cstartmonth: "",
            // 参考当量结束月份
            Cendmonth: "",
            // 模拟测算月份
            // 时间选择器弹出框
            dataIntervalVisible: false,
            // 科室绩效模拟测算弹出框
            createEchartVisible: false,
            // 创建检测规则弹出框
            createDetectionVisible: false,
            // 表格数据
            tableCreateDeData: [],
            tableSimulationData: [],
            DateOneMonthModel: {
                MonthOnempnthTime: "",
            },
            DateOneMonthRules: {
                MonthOnempnthTime: [{ required: true, message: "请选择日期" }],
            },
            // 选择参考当量-月份区间
            DateBetweenMonthModel: {
                MonthDate: "",
            },
            DateBetweenMonthRules: {
                MonthDate: [{ required: true, message: "请选择日期" }],
            },
            Keshitypeoptions: [],
            // 参考当量值
            EquivalentValue: "",
            // 创建检测规则
            AWorkMonitModel: {
                // 监测名称：
                name: "",
                // 参考当量：
                yearzhi: "",
                // 测算月份绩效薪酬
                monthPrice: "",
                // 计算难度系数
                diffType: "1",
                // 模拟测算月份
                endMonthzhi: "",
                // 选择科室
                type: "",
                // 参考当量值
                EquivalentValue: "",
            },
            AWorkMonitRules: {
                // 监测名称：
                name: [
                    {
                        required: true,
                        message: "请输入名称",
                        trigger: "blur",
                    },
                ],
                // 选择科室
                type: [
                    {
                        required: true,
                        message: "请选择科室",
                        trigger: "change",
                    },
                ],
                // 参考当量：
                yearzhi: [
                    // {
                    //   required: true,
                    //   message: "请选择参考当量",
                    //   trigger: "blur",
                    // },
                    { required: true, validator: departCheck, trigger: "blur" },
                ],
                EquivalentValue: [
                    {
                        required: true,
                        message: "请填写当量值",
                        trigger: "blur",
                    },
                ],
                monthPrice: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                endMonthzhi: [
                    {
                        required: true,
                        message: "请选择模拟测算月份",
                        trigger: "blur",
                    },
                ],
                diffType: [
                    {
                        required: true,
                        message: "请选择计算难度系数",
                        trigger: "change",
                    },
                ],
            },
            // 总页数
            total: 1,
            // 每页页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            monitorTitle: "",
            // 模拟测评id
            id: "",
            // 模拟测算月份
            simulationMonth: "",
            workValueHe: "",
            incomeHe: "",
            // 选择科室的科室id
            KeshiDataListvalue: "",
            // 所有年份
            DlYear: [],
            // 点击测算2秒置灰
            cesuanClick: true,
            cesuanClickKing: false,
            // 结束年份
            endYear: "",
            // 参考当量类型 0月度区间 1单月
            equivalentType: "",
            // 参考当量的类型 1月份区间 2单月
            computesType: "",
        };
    },
    watch: {
        "AWorkMonitModel.yearzhi": function (val) {
            if (val !== "") {
                this.findvalent();
            }
        },
    },
    created() {
        this.getSimulationList();
        // 之前的接口只能获取到年份
        // this.getHospitalYear();
    },
    methods: {
        // 设置表格的颜色
        columnStyle({ row, column, rowIndex, columnIndex }) {
            if (
                columnIndex === 0 ||
                columnIndex === 1 ||
                columnIndex === 2 ||
                columnIndex === 3 ||
                columnIndex === 4 ||
                columnIndex === 5
            ) {
                // 返回背景颜色
                return "background-color:#d6d6d6;!important";
            }
        },
        async getYearAndMonth(KeshiDataListvalue) {
            this.creactMonthArr = [];
            let data = {
                departmentId: KeshiDataListvalue, //科室id
            };
            let { data: res } = await this.$axios.getYearAndMonth(data);
            // console.log(res)
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取科室模拟测算列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                let arr = [];
                for (let i = 0; i < res.data.length; i++) {
                    arr.push({ year: res.data[i].year, month: [] });
                    for (
                        let j = 0;
                        j < res.data[i].departmetMonthDetails.length;
                        j++
                    ) {
                        // console.log(res.data[i].departmetMonthDetails[j].month);
                        if (res.data[i].departmetMonthDetails[j].month < 10) {
                            arr[i].month.push({
                                month:
                                    res.data[i].year +
                                    "-" +
                                    "0" +
                                    res.data[i].departmetMonthDetails[j].month,
                            });
                        } else {
                            arr[i].month.push({
                                month:
                                    res.data[i].year +
                                    "-" +
                                    res.data[i].departmetMonthDetails[j].month,
                            });
                        }
                    }
                }
                // console.log(arr)
                for (let i = 0; i < arr.length; i++) {
                    // console.log(...arr[i].month)
                    for (let j = 0; j < arr[i].month.length; j++) {
                        // console.log(arr[i].month[j].month)
                        this.creactMonthArr.push(arr[i].month[j].month);
                        // console.log(this.creactMonthArr);
                    }
                }
            }
        },
        // 之前的接口 获取医院所有年份 不分页
        async getHospitalYear() {
            let data = {
                hospitalId: this.hospitalId, //科室id
            };
            let { data: res } = await this.$axios.getHospitalYear(data);
            // console.log(res)
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取科室模拟测算列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                res.data.forEach((item) => {
                    // console.log(item)
                    this.DlYear.push(item.year);
                });
            }
        },
        workValuehE(e) {
            this.workValueHe = e;
            if (Number(e) + Number(this.getworkldModel.income) > 100) {
                this.getworkldModel.income = 20;
                this.getworkldModel.workValue = 80;
                this.$message({
                    message: "工作当量和收支余不能超过100%",
                    type: "error",
                });
            }
        },
        incomehE(e) {
            this.incomeHe = e;
            if (Number(e) + Number(this.getworkldModel.workValue) > 100) {
                this.getworkldModel.income = 20;
                this.getworkldModel.workValue = 80;
                this.$message({
                    message: "工作当量和收支余不能超过100%",
                    type: "error",
                });
            }
        },
        typeKeshiValueChange(KeshiDataListvalue) {
            this.KeshiDataListvalue = KeshiDataListvalue;
            // 2022-4-19修改 获取年份和月份
            this.getYearAndMonth(KeshiDataListvalue);
        },
        // 点击月份区间选择
        BetweenMonth() {
            this.dataBetweenMonthVisible = true;
        },
        // 点击选择一个月
        oneMonth() {
            this.dataOneMonthVisible = true;
        },
        //   科室模拟测算列表
        async getSimulationList() {
            let data = {
                // departmentId: this.departmentId, //科室id

                // 2022-4-12 修改
                departmentId: "", //科室id
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示数量
                orderByColumn: "createDate",
                isAsc: "desc",
            };
            let { data: res } = await this.$axios.getSimulationList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取科室模拟测算列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.tableCreateDeData = res.rows;
                this.total = res.total;
            }
        },
        // 点击参考当量的日期
        dataInterval() {
            // 判断选择科室有没有选择
            // console.log(this.KeshiDataListvalue);
            if (this.KeshiDataListvalue && this.MoNCendmonth) {
                this.referenceShow = false;
                this.dataIntervalVisible = true;
            } else {
                this.referenceShow = true;
            }
        },
        // 参考当量值的失去焦点事件
        async findvalent() {
            // console.log(this.AWorkMonitModel);
            if (
                this.AWorkMonitModel.type !== "" &&
                this.AWorkMonitModel.endMonthzhi !== "" &&
                this.AWorkMonitModel.yearzhi !== ""
            ) {
                let data = _qs.stringify({
                    departmentId: this.KeshiDataListvalue, //科室id
                    year: this.Cyear, //参考当量年份
                    startMonth: this.Cstartmonth, //参考当量 开始月份
                    endMonth: this.Cendmonth, //参考当量 结束月份
                    simulationMonth: this.MoNCendmonth, //模拟测算月份
                    endYear: this.endYear, //参考当量结束年份（如果参考当量是单月，则该参数不用传）
                    computesType: this.computesType, //参考当量类型 0月度区间 1单月
                });
                let { data: res } = await this.$axios.findEquivalent(data);
                // console.log(res)
                if (res.code == 401) {
                    this.$router.push("/login");
                } else if (res.code == 200) {
                    this.AWorkMonitModel.EquivalentValue = res.data.workValue;
                } else {
                    this.$message({
                        message: res.data,
                        type: "error",
                    });
                }
            }
        },
        // 创建测算规则
        createDetec() {
            this.createDetectionVisible = true;
            this.getDepartmentNotPage();
        },
        // 点击创建检测规则时候取消正则
        createDetectionVisibleClose() {
            this.createDetectionVisible = false;
            this.$refs.AWorkMonitRef.resetFields();
            this.referenceShow = false;
            this.KeshiDataListvalue = "";
            // 参考当量值为空
            this.EquivalentValue = "";
            this.addCreateDis = false;
        },
        // 科室绩效模拟测算弹出框关闭
        createEchartVisibleClose() {
            this.createEchartVisible = false;
            this.$refs.getworkldRef.resetFields();
        },
        // 选择一个月的日期改变
        changeOptiosOnemonth(val) {
            if (val) {
                this.startTime = val;
                // 年
                this.Cyear = val.split("-")[0];
                // 月
                let month = val.split("-")[1];
                // this.Cstartmonth = month;

                if (month < 10) {
                    this.Cstartmonth = month.split("0")[1];
                } else {
                    this.Cstartmonth = month;
                }
                this.Cendmonth = month;
                this.endYear = "";
                this.equivalentType = 1;
                this.computesType = 2;
            }
        },
        // 选择一个月的弹出框关闭
        dataOneMonthVisibleClose() {
            this.dataOneMonthVisible = false;
            this.$refs.DateOneMonthRef.resetFields();
        },
        // 选择一个月的确定按钮
        DateOneMonthPrimary() {
            this.$refs.DateOneMonthRef.validate(async (valid) => {
                if (valid) {
                    this.dataOneMonthVisible = false;
                    this.dataIntervalVisible = false;
                    this.AWorkMonitModel.yearzhi =
                        this.Cyear + "-" + this.Cstartmonth;
                    this.$refs.DateOneMonthRef.resetFields();
                }
            });
        },
        // 参考当量月份区间选择
        changeEndequivalent(val) {
            // console.log(val);
            if (val) {
                val.forEach((element) => {
                    // console.log(element);
                });
            }
        },
        // 选择月份区间的弹出关闭
        dataBetweenMonthVisibleClose() {
            this.dataBetweenMonthVisible = false;
            this.$refs.DateBetweenMonthRef.resetFields();
        },
        // 选择月份区间的确定按钮
        DateBetweenMonthPrimary() {
            this.$refs.DateBetweenMonthRef.validate(async (valid) => {
                if (valid) {
                    this.dataBetweenMonthVisible = false;
                    this.dataIntervalVisible = false;
                    // console.log(this.DateBetweenMonthModel.MonthDate);
                    // console.log(this.DateBetweenMonthModel.MonthDate[0])
                    // console.log(this.DateBetweenMonthModel.MonthDate[1])

                    // 开始年份
                    this.Cyear =
                        this.DateBetweenMonthModel.MonthDate[0].split("-")[0];
                    // 开始月份
                    let startres =
                        this.DateBetweenMonthModel.MonthDate[0].split("-")[1];
                    if (startres) {
                        if (startres < 10) {
                            this.Cstartmonth = startres.split("0")[1];
                        } else {
                            this.Cstartmonth = startres;
                        }
                    }

                    // 结束年份
                    this.endYear =
                        this.DateBetweenMonthModel.MonthDate[1].split("-")[0];
                    // 结束月份
                    let endRes =
                        this.DateBetweenMonthModel.MonthDate[1].split("-")[1];
                    if (endRes) {
                        if (endRes < 10) {
                            this.Cendmonth = endRes.split("0")[1];
                        } else {
                            this.Cendmonth = endRes;
                        }
                    }

                    this.equivalentType = 0;
                    this.computesType = 1;

                    this.AWorkMonitModel.yearzhi =
                        this.Cyear +
                        "-" +
                        this.Cstartmonth +
                        "至" +
                        this.endYear +
                        "-" +
                        this.Cendmonth;

                    this.$refs.DateBetweenMonthRef.resetFields();
                }
            });
        },
        // 模拟测算月份的日期改变
        changeOptiosEnd(val) {
            if (val) {
                this.Eyear = val.split("-")[0];
                let month = val.split("-")[1];
                if (month < 10) {
                    month = month.split("0")[1];
                }
                this.MoNCendmonth = this.Eyear + "-" + month;
            }
        },
        // 点击创建检测规则的创建
        addCreateDetecItem() {
            if (this.KeshiDataListvalue) {
                this.referenceShow = false;
            } else {
                this.referenceShow = true;
            }

            this.$refs.AWorkMonitRef.validate(async (valid) => {
                if (valid) {
                    this.addCreatClick = false;
                    this.addCreateDis = true;
                    if (Number(this.MoNCendmonth) < 10) {
                        this.MoNCendmonth = this.MoNCendmonth.split("0")[1];
                    } else {
                        this.MoNCendmonth = this.MoNCendmonth;
                    }
                    let data = _qs.stringify({
                        name: this.AWorkMonitModel.name, //名称
                        departmentId: this.KeshiDataListvalue, //科室id
                        year: this.Cyear, //参考当量年份
                        startMonth: this.Cstartmonth, //参考当量 开始月份
                        endMonth: this.Cendmonth, //参考当量 结束月份
                        monthPrice: this.AWorkMonitModel.monthPrice, //测算月份绩效薪酬
                        // diffType: this.AWorkMonitModel.diffType, //计算难度系数 0 月度 1 季度
                        diffType: this.AWorkMonitModel.diffType, // 计算难度系数 1计算难度系数 2不计算难度系数
                        simulationMonth: this.MoNCendmonth, //模拟测算月份
                        equivalentType: this.equivalentType, //参考当量类型 0月度区间 1单月
                        endYear: this.endYear, //参考当量结束年份（如果参考当量是单月，则该参数不用传）
                        oneValue: this.AWorkMonitModel.EquivalentValue, //参考当量系数
                    });
                    let { data: res } = await this.$axios.createSimulation(
                        data
                    );
                    // console.log(res);
                    this.addCreatClick = true;
                    this.addCreateDis = false;
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        this.$message({
                            message: "创建科室模拟测算成功",
                            type: "success",
                        });
                        this.createDetectionVisible = false;
                        this.getSimulationList();
                        this.$refs.AWorkMonitRef.resetFields();
                        this.DateOneMonthModel = {};
                    } else {
                        this.$message({
                            message: res.data,
                            type: "error",
                        });
                    }
                }
            });
        },
        // 点击表格的操作的删除
        async WorkMonitDelete(row) {
            // console.log(row);
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let { data: res } = await this.$axios.removeSimulation(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "删除失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.$message({
                    message: "删除成功",
                    type: "success",
                });
                this.getSimulationList();
            }
        },
        // 点击表格操作的科室绩效模拟测算
        async WorkMonitData(row) {
            this.simulationMonth = row.simulationMonth;
            // this.monitorTitle = row.year + "年" + row.endMonth + "月绩效模拟测算";
            this.monitorTitle = row.name;
            this.createEchartVisible = true;
            this.id = row.id;
            let data = {
                id: row.id, //模拟测评id
            };
            let { data: res } = await this.$axios.getSimulationCalculate(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取单个模拟测算数据失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.tableSimulationData = [res.data];
                this.getworkldModel.workValue = res.data.workValue;
                this.getworkldModel.income = res.data.income;
                this.simulationCalculate("1");
            }
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.getSimulationList();
        },
        // 点击测算
        async simulationCalculate(sum) {
            // 绩效模拟测算按钮点击后置灰2秒
            if (sum !== "1") {
                this.cesuanClick = false;
                setTimeout(() => {
                    this.cesuanClick = true;
                }, 2000);
            }
            this.$refs.getworkldRef.validate(async (valid) => {
                if (valid) {
                    this.cesuanClick = false;
                    this.cesuanClickKing = true;
                    let data = _qs.stringify({
                        id: this.id, //模拟测评id
                        workValue: this.getworkldModel.workValue, //工作当量 不传百分号，百分号放在输入框后面
                        income: this.getworkldModel.income, //收支余 不传百分号，百分号放在输入框后面
                    });
                    let { data: res } = await this.$axios.calculateData(data);
                    // console.log(res);
                    this.cesuanClick = true;
                    this.cesuanClickKing = false;
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        this.createDetectionVisible = false;
                        this.tableSimulationData = [res.data];
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                }
            });
        },
        //获取当前年月日
        addDate() {
            const nowDate = new Date();
            const date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                date: nowDate.getDate(),
            };
            const newmonth = date.month >= 10 ? date.month : "0" + date.month;
            const day = date.date >= 10 ? date.date : "0" + date.date;
            let updateTime = date.year + "-" + newmonth + "-" + day;
            return updateTime;
        },
        // 点击导出数据
        async exportExce() {
            this.cesuanExpClick = false;
            this.cesuanExpClickTwo = true;
            let params = {
                id: this.id,
            };
            let res = await this.$axios.importSimulationExcel(params);
            // console.log(res);
            if (res.status == 200) {
                if (res.data.code == 401) {
                    this.$router.push("/login");
                } else if (res.code == 500) {
                    this.$message({
                        message: "导出失败",
                        type: "error",
                    });
                } else if (res.status == 200) {
                    let updateTime = this.addDate();
                    let blob = new Blob([res.data], {
                        type: "application/x-xls",
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    //配置下载的文件名
                    link.download =
                        this.monitorTitle + updateTime + "模拟测算.xls";
                    link.click();
                    this.cesuanExpClick = true;
                    this.cesuanExpClickTwo = false;
                }
            }
        },
        // 获取医院所属科室列表
        async getDepartmentNotPage() {
            let data = {
                hospitalId: this.hospitalId,
            };
            let { data: res } = await this.$axios.getDepartmentNotPage(data);
            // console.log(res)
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取医院所属科室列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.Keshitypeoptions = res.data;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.el-form--inline .el-form-item {
    margin-right: 0px;
}
.dataInterval {
    display: flex;
    .dataInput {
        width: 170px;
    }
    .el-icon-date {
        font-size: 30px;
        line-height: 40px;
        margin-left: 2px;
        color: #afa7a7;
        cursor: pointer;
    }
}

.centerZhi {
    margin-left: 10px;
    margin-right: 10px;
}

.hosrow {
    margin: 0 auto;
    margin-bottom: 10px;
    .Basicbutto {
        float: right;
    }
}

.WorkMForm {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0  auto;
    .el-input {
        width: 250px !important;
    }
    .el-select {
        width: 250px !important;
    }
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.el-pagination {
    margin-top: 20px;
}

.reference {
    height: 40px;
    position: relative;
    // right: 41px;
}

.EquivalentValue {
    margin-top: -62px;
    // margin-left: 326px;
    margin-left: 170px;
}

.referencetips {
    position: absolute;
    left: 0;
    bottom: -30px;
    color: red;
    font-size: 12px;
}

.btnCen {
    text-align: center;
    .el-button {
        width: 150px;
    }
}
</style>
